import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import {
  CircularProgress,
  Typography,
  Container,
  Fab,
  Zoom,
  Icon
} from '@material-ui/core'
import Project from './Project'
import ProjectModal from './ProjectModal'

const Projects = ({ archived, history }) => {
  const [createProject, toggleCreateProject] = useState(false)
  useFirestoreConnect([
    { collection: 'projects', where: ['archived', '==', archived || false] }
  ])
  const projects = useSelector(state => state.firestore.ordered.projects)
  return (
    <div className="projects">
      <Container maxWidth="sm">
        <div
          style={{
            marginBottom: 20,
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Zoom in>
            <Fab
              onClick={() => toggleCreateProject(!createProject)}
              variant="extended"
              size="medium"
              aria-label="add"
            >
              <Icon>create</Icon> Create Project
            </Fab>
          </Zoom>
        </div>
        {!isLoaded(projects) && <CircularProgress />}
        {isLoaded(projects) && isEmpty(projects) && (
          <Typography variant="h5">No Projects</Typography>
        )}
        {!isEmpty(projects) &&
          isLoaded(projects) &&
          projects.map(project => (
            <Project key={project.id} project={project} />
          ))}
        <ProjectModal toggleEdit={toggleCreateProject} open={createProject} />
      </Container>
    </div>
  )
}

export default withRouter(Projects)
