import React from 'react'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import * as Colors from '@material-ui/core/colors'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import store from './store'

import ProjectDetail from './views/ProjectDetail'
import Admin from './views/Admin'

import GAListener from './wrappers/GAListener'

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            light: Colors.grey[600],
            main: Colors.grey[900],
            dark: Colors.grey[900]
          },
          secondary: Colors.red,
          type: prefersDarkMode ? 'dark' : 'light',
          background: prefersDarkMode ? '#121212' : '#ccc'
        }
      }),
    [prefersDarkMode]
  )
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <GAListener>
              <Switch>
                <Route exact path="/">
                  No Project ID given
                </Route>
                <Route exact path="/projects/:id">
                  <ProjectDetail />
                </Route>
                <Route exact path="/sc-admin">
                  <Admin />
                </Route>
              </Switch>
            </GAListener>
          </Router>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

export default App
