import React from 'react'
import { Typography, Paper, Hidden, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export default () => {
  return (
    <div className="milestone">
      <Paper className="milestone-card" elevation={0}>
        <Grid container>
          <Grid style={{ flex: 1 }} item xs={'auto'}>
            <Typography
              variant="caption"
              component="div"
              className="inline-date"
            >
              <Skeleton variant="text" height={19} width={150} />
            </Typography>
            <Typography gutterBottom variant="h5">
              <Skeleton variant="text" width={200} height={31} />
            </Typography>
            <Typography
              style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              component={'div'}
              className="notes"
              variant="body1"
            >
              <Skeleton variant="text" height={24} />
              <Skeleton variant="text" height={24} />
              <Skeleton variant="text" height={24} />
              <Skeleton variant="text" width={80} height={24} />
            </Typography>
          </Grid>
          <Grid item xs={'auto'}></Grid>
        </Grid>
        {/* <Attachments project={project} milestone={milestone} />
        <Comments project={project} milestone={milestone} /> */}
      </Paper>
    </div>
  )
}
