import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Typography, Card, CardContent, useMediaQuery } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

// import ReactHtmlParser from 'react-html-parser'
// import transform from '../../../helpers/transform'

export default ({ milestone }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const [comments, setComments] = useState(null)
  useEffect(() => {
    async function getData() {
      const res = await axios.get(
        `https://app.asana.com/api/1.0/tasks/${milestone.gid}/stories?opt_fields=gid,text,html_text,type&limit=100`,
        {
          headers: {
            Authorization: 'Bearer 0/4eb64c01291bf2b493407f502aa3b76e'
          }
        }
      )
      const comments = res.data.data.filter(story => story.type === 'comment')
      setComments(comments)
    }
    getData()
  }, [milestone.gid]) // <-- Have to pass in [] here!
  return comments === null ? (
    <div className="attachments">
      <Typography className="sub-header">Comments</Typography>
      <Card
        style={{
          backgroundColor: prefersDarkMode
            ? 'rgba(0,0,0,0.16)'
            : 'rgba(236, 239, 241, 0.5)',
          marginTop: 12
        }}
      >
        <CardContent>
          <Typography
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            component={'div'}
            className="notes"
            variant="body1"
          >
            <Skeleton variant="text" height={19} />
            <Skeleton variant="text" height={19} />
            <Skeleton variant="text" width={120} height={19} />
          </Typography>
        </CardContent>
      </Card>
      <Card
        style={{
          backgroundColor: prefersDarkMode
            ? 'rgba(0,0,0,0.16)'
            : 'rgba(236, 239, 241, 0.5)',
          marginTop: 12
        }}
      >
        <CardContent>
          <Typography
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            component={'div'}
            className="notes"
            variant="body1"
          >
            <Skeleton variant="text" height={19} />
            <Skeleton variant="text" height={19} />
            <Skeleton variant="text" width={120} height={19} />
          </Typography>
        </CardContent>
      </Card>
    </div>
  ) : comments && comments.length ? (
    <div className="attachments">
      <Typography className="sub-header">Notes</Typography>
      {comments.map(att => (
        <Card
          elevation={prefersDarkMode ? 1 : 0}
          style={{
            backgroundColor: prefersDarkMode
              ? 'rgba(0,0,0,0.16)'
              : 'rgba(236, 239, 241, 0.5)',
            marginTop: 12
          }}
          key={att.gid}
        >
          <CardContent>
            <Typography
              style={{ whiteSpace: 'pre-wrap' }}
              component={'div'}
              className="notes"
              variant="body1"
              dangerouslySetInnerHTML={{ __html: att.html_text }}
            >
              {/* {ReactHtmlParser(att.html_text, {
                transform
              })} */}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  ) : null
}
