import React from 'react'
import {
  Typography,
  AppBar,
  Toolbar,
  Container,
  useMediaQuery
} from '@material-ui/core'

export default ({ project }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  return (
    <AppBar position="sticky" color={prefersDarkMode ? 'inherit' : 'primary'}>
      <Toolbar disableGutters>
        <Container className="header-container" maxWidth="sm">
          <div className="header-title">
            <Typography style={{ lineHeight: 1.1 }} variant="caption">
              {project.client}
            </Typography>
            <Typography style={{ lineHeight: 1.2 }} variant="h6">
              {project.name}
            </Typography>
          </div>
          <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203 336">
              <g fill="#FFFFFE" fillRule="evenodd">
                <path d="M81.798 115.292l-62.535 36.104V79.187l62.535 36.105zm9.659-88.898v72.473L28.822 62.563l62.635-36.169zm19.23 0l62.635 36.169-62.635 36.304V26.394zM4.548 176.187l55.966 32.312c1.407.812 3.141.813 4.548.001l13.944-8.047c1.299-.751 1.299-2.626 0-3.377l-50.19-28.977 169.049-97.704c6.063-3.504 6.058-12.258-.008-15.756l-92.305-53.22c-2.812-1.621-6.275-1.621-9.087 0l-91.913 53C1.736 56.043 0 59.047 0 62.299V168.31c0 3.249 1.734 6.252 4.548 7.877z" />
                <path d="M183.146 257.015l-62.534-36.104 62.534-36.104v72.208zm-72.194 52.793v-72.472l62.635 36.304-62.635 36.168zm-19.229 0L29.088 273.64l62.635-36.304v72.472zm106.138-149.792l-55.966-32.313c-1.407-.812-3.14-.812-4.547 0l-13.944 8.047c-1.3.75-1.3 2.626-.001 3.376l50.19 28.977L4.544 265.808c-6.062 3.504-6.058 12.257.009 15.755l92.304 53.221c2.812 1.621 6.275 1.621 9.087-.001l91.913-52.999c2.817-1.625 4.552-4.629 4.552-7.88V167.893c0-3.25-1.734-6.253-4.548-7.877z" />
              </g>
            </svg>
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  )
}
