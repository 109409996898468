import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { CircularProgress } from '@material-ui/core'
import Header from './components/Header'
import HarvestCard from './components/HarvestCard'
import Feed from './components/Feed'

const ProjectDetail = props => {
  const projectID = props.match.params.id
  useFirestoreConnect([
    { collection: 'projects', doc: projectID } // or 'todos'
  ])
  const project = useSelector(
    ({ firestore: { data } }) => data.projects && data.projects[projectID]
  )
  if (project)
    return (
      <div className="project-detail">
        <Header project={project} />
        {project && project.harvest_id ? (
          <HarvestCard project={project} />
        ) : (
          <div style={{ padding: '10px 0' }} />
        )}
        <Feed project={{ id: projectID, ...project }} />
      </div>
    )
  return <CircularProgress />
}

export default withRouter(ProjectDetail)
