import React from 'react'
import firebase from 'firebase'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router-dom'

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    const { history } = this.props
    firebase.analytics().setAnalyticsCollectionEnabled(true)
    this.sendPageView(history.location)
    history.listen(this.sendPageView)
  }

  sendPageView(location) {
    console.log('GAListener', location.pathname)
    firebase.analytics().setCurrentScreen(location.pathname)
  }

  render() {
    return this.props.children
  }
}

export default withRouter(GAListener)
