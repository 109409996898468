import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import Milestone from './Milestone'
import MilestonePlaceholder from './MilestonePlaceholder'

export default ({ section, project }) => {
  const [milestones, setMilestones] = useState(null)
  useEffect(() => {
    async function getData() {
      const res = await axios.get(
        `https://app.asana.com/api/1.0/sections/${section.gid}/tasks?opt_fields=name,resource_subtype,due_on,due_at,completed,id,notes,html_notes&limit=100&archived=false`,
        {
          headers: {
            Authorization: 'Bearer 0/4eb64c01291bf2b493407f502aa3b76e'
          }
        }
      )
      const milestones = res.data.data.filter(
        m => m.resource_subtype === 'milestone'
      )

      const sortedMilestones = milestones.sort(
        (a, b) =>
          new moment(a.due_on).format('YYYYMMDD') -
          new moment(b.due_on).format('YYYYMMDD')
      )

      setMilestones(sortedMilestones)
    }
    getData()
  }, [section.gid]) // <-- Have to pass in [] here!
  return (
    <div className="milestones">
      {!milestones ? (
        <React.Fragment>
          <MilestonePlaceholder />
          <MilestonePlaceholder />
          <MilestonePlaceholder />
          <MilestonePlaceholder />
          <MilestonePlaceholder />
          <MilestonePlaceholder />
        </React.Fragment>
      ) : (
        milestones.map(milestone => (
          <Milestone
            key={milestone.gid}
            project={project}
            milestone={milestone}
          />
        ))
      )}
    </div>
  )
}
