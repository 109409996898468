import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Paper,
  Switch,
  FormControlLabel,
  FormGroup
} from '@material-ui/core'
import { useFirestore } from 'react-redux-firebase'
import _ from 'lodash'

import ProjectModal from './ProjectModal'

const Project = ({ project, history }) => {
  const firestore = useFirestore()
  const [editing, toggleEdit] = useState(false)

  const archive = _.debounce(archived => {
    const confirm = window.confirm(
      `Are you sure you want to ${
        archived ? 'archive' : 'unarchive'
      } this project?`
    )

    if (confirm) {
      firestore
        .collection('projects')
        .doc(project.id)
        .update({
          archived
        })
    }
  }, 320)

  return (
    <Card className="project">
      <CardContent>
        <Typography color="textSecondary" gutterBottom style={{ fontSize: 14 }}>
          {project.client}
        </Typography>
        <Typography variant="h5" component="h2">
          {project.name}
        </Typography>
      </CardContent>
      <CardActions>
        <div style={{ flexGrow: 1 }}>
          <Paper
            elevation={0}
            style={{ display: 'inline-block', marginRight: 8 }}
          >
            <Button onClick={() => toggleEdit(!editing)}>Edit</Button>
          </Paper>
          <Paper elevation={0} style={{ display: 'inline-block' }}>
            <Button onClick={() => history.push(`/projects/${project.id}`)}>
              View
            </Button>
          </Paper>
        </div>
        <FormGroup row>
          <FormControlLabel
            labelPlacement="start"
            style={{ marginRight: 0 }}
            control={
              <Switch
                checked={project.archived}
                onChange={event => archive(event.currentTarget.checked)}
                value={'archived'}
                inputProps={{ 'aria-label': 'Archived' }}
              />
            }
            label="Archived"
          />
        </FormGroup>
      </CardActions>
      <ProjectModal project={project} open={editing} toggleEdit={toggleEdit} />
    </Card>
  )
}

export default withRouter(Project)
