import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Typography, Icon, Button } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export default ({ milestone }) => {
  const [attachments, setAttachments] = useState(null)
  useEffect(() => {
    async function getData() {
      const res = await axios.get(
        `https://app.asana.com/api/1.0/tasks/${milestone.gid}/attachments?opt_fields=gid,resource_type,download_url,name,view_url&limit=100`,
        {
          headers: {
            Authorization: 'Bearer 0/4eb64c01291bf2b493407f502aa3b76e'
          }
        }
      )
      const attachments = res.data.data
      setAttachments(attachments)
    }
    getData()
  }, [milestone.gid]) // <-- Have to pass in [] here!
  return !attachments === null ? (
    <div className="attachments">
      <Typography className="sub-header">ATTACHMENTS</Typography>
      <div style={{ display: 'flex' }}>
        <Skeleton
          style={{ marginRight: 4 }}
          variant="rect"
          width={160}
          height={36}
        />
        <Skeleton variant="rect" width={200} height={36} />
      </div>
    </div>
  ) : attachments && attachments.length ? (
    <div className="attachments">
      <Typography className="sub-header">ATTACHMENTS</Typography>
      {attachments.map(att => (
        <Button
          style={{ marginRight: 4 }}
          startIcon={<Icon>attachment</Icon>}
          download
          // size="small"
          variant="outlined"
          href={att.download_url}
          target="_blank"
          key={att.gid}
        >
          {att.name}
        </Button>
      ))}
    </div>
  ) : null
}
