import React, { useEffect } from 'react'
import axios from 'axios'
import { Paper, Grid, Typography, Hidden } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export default ({ harvest }) => {
  const [harvestData, setHarvestData] = React.useState(null)
  // const [timeEntries, setTimeEntries] = React.useState(null)
  const [budgetSpent, setBudgetSpent] = React.useState(0)
  const [budgetRemaining, setBudgetRemaining] = React.useState(0)
  const [budgetTotal, setBudgetTotal] = React.useState(0)
  const formatMoney = (
    amount,
    decimalCount = 2,
    decimal = '.',
    thousands = ','
  ) => {
    try {
      decimalCount = Math.abs(decimalCount)
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount

      const negativeSign = amount < 0 ? '-' : ''

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString()
      let j = i.length > 3 ? i.length % 3 : 0

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      )
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    async function getData() {
      const res = await axios.get(
        `https://api.harvestapp.com/v2/projects/${harvest}`,
        {
          headers: {
            'Harvest-Account-Id': 127835,
            Authorization:
              'Bearer 1594160.pt.OvoKXwD2_4zQJk_jG9l8pE9ggj64GQ8NMmbhTygCXNsGkrMOagxgA8-uyYPdXgA-ebNGeBcX9ghdrifbTE3s8Q'
          }
        }
      )
      let budget = res.data.cost_budget
      console.log(res.data)
      setHarvestData(res.data)
      setBudgetTotal(res.data.cost_budget)
      await axios
        .get(
          `https://api.harvestapp.com/v2/time_entries?project_id=${harvest}`,
          {
            headers: {
              'Harvest-Account-Id': 127835,
              Authorization:
                'Bearer 1594160.pt.OvoKXwD2_4zQJk_jG9l8pE9ggj64GQ8NMmbhTygCXNsGkrMOagxgA8-uyYPdXgA-ebNGeBcX9ghdrifbTE3s8Q'
            }
          }
        )
        .then(res => {
          // setTimeEntries(res.data)
          let spent = 0
          let remaining = budget
          res.data.time_entries.forEach(entry => {
            if (entry.billable) {
              const total = entry.billable_rate * entry.hours
              spent += total
              remaining -= total
            }
          })
          setBudgetSpent(spent)
          setBudgetRemaining(remaining)
        })
    }
    getData()
  }, [harvest]) // <-- Have to pass in [] here!

  return (
    <React.Fragment>
      {!harvestData ? (
        <Paper className="harvest-card-inner" elevation={3}>
          <Grid spacing={0} container>
            <Grid xs={12} item>
              <Typography className="sub-header">--</Typography>
            </Grid>
            <Grid xs={12} item>
              <div className="data-bar">
                <Skeleton variant="rect" height={34} />
              </div>
            </Grid>
            <Grid className="data-points" container spacing={0}>
              <div className="data-point">
                <Typography className="sub-header">SPENT</Typography>
                <Skeleton variant="text" width={120} heigh={31} />
              </div>
              <Hidden xsDown>
                <div className="divider" />
              </Hidden>
              <div className="data-point">
                <Typography className="sub-header">REMAINING</Typography>
                <Skeleton variant="text" width={120} heigh={31} />
              </div>
              <Hidden xsDown>
                <div className="divider" />
              </Hidden>
              <div className="data-point">
                <Typography className="sub-header">TOTAL</Typography>
                <Skeleton variant="text" width={120} heigh={31} />
              </div>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper className="harvest-card-inner" elevation={3}>
          <Grid spacing={0} container>
            <Grid xs={12} item>
              <Typography className="sub-header">{harvestData.name}</Typography>
            </Grid>
            <Grid xs={12} item>
              <div
                className={`data-bar ${
                  budgetRemaining < 0 ? 'over-budget' : ''
                }`}
              >
                <div
                  className="data-bar-inner"
                  style={{
                    width: `${
                      budgetSpent ? (budgetSpent / budgetTotal) * 100 : 0
                    }%`
                  }}
                />
                {budgetRemaining < 0 && (
                  <div
                    className="data-bar-inner overage-bar"
                    style={{
                      width: `${(Math.abs(budgetRemaining) / budgetTotal) *
                        100}%`
                    }}
                  />
                )}
              </div>
            </Grid>
            <Grid className="data-points" container spacing={0}>
              <div className="data-point">
                <Typography className="sub-header">SPENT</Typography>
                <Typography variant="h5">
                  ${formatMoney(budgetSpent)}
                </Typography>
              </div>
              <Hidden xsDown>
                <div className="divider" />
              </Hidden>
              <div className="data-point">
                <Typography className="sub-header">REMAINING</Typography>
                <Typography
                  variant="h5"
                  color={`${budgetRemaining < 0 ? 'error' : 'inherit'}`}
                  // className={`${budgetRemaining < 0 ? 'error' : ''}`}
                >
                  ${formatMoney(budgetRemaining)}
                </Typography>
              </div>
              <Hidden xsDown>
                <div className="divider" />
              </Hidden>
              <div className="data-point">
                <Typography className="sub-header">TOTAL</Typography>
                <Typography variant="h5">
                  ${formatMoney(budgetTotal)}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  )
}
