import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Container } from '@material-ui/core'
import axios from 'axios'
import SwipeableViews from 'react-swipeable-views'
import { withRouter } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import FeedTab from './Tab'
import MilestonePlaceholder from './MilestonePlaceholder'

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontSize: 14,
    minWidth: 0,
    padding: '6px 2px',
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(3),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  selected: {}
}))(props => <Tab className="feed-tab" disableRipple {...props} />)

const Feed = ({ project, history }) => {
  const [active, setActive] = useState(0)
  const [sections, setSections] = useState(null)

  useEffect(() => {
    async function getData() {
      const res = await axios.get(
        `https://app.asana.com/api/1.0/projects/${project.asana_id}/sections?opt_fields=gid,resource_type,name&limit=100`,
        {
          headers: {
            Authorization: 'Bearer 0/4eb64c01291bf2b493407f502aa3b76e'
          }
        }
      )
      const sections = res.data.data
      setSections(sections.filter(section => section.name.includes('[TAB] ')))
    }
    getData()
  }, [project.asana_id]) // <-- Have to pass in [] here!

  const handleCallToRouter = value => {
    console.log('test', value)
    history.push(value)
  }

  const onChange = (e, active) => {
    setActive(active)
    handleCallToRouter(active)
  }
  const handleChangeIndex = index => {
    setActive(index)
  }
  return (
    <Container maxWidth="sm" className="feed">
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        className="feed-tabs"
        value={active}
        onChange={onChange}
      >
        {sections === null && (
          <StyledTab
            disabled
            label={<Skeleton variant="text" width={60} height={24} />}
          />
        )}
        {sections === null && (
          <StyledTab
            disabled
            label={<Skeleton variant="text" width={40} height={24} />}
          />
        )}
        {sections === null && (
          <StyledTab
            disabled
            label={<Skeleton variant="text" width={50} height={24} />}
          />
        )}
        {sections === null && (
          <StyledTab
            disabled
            label={<Skeleton variant="text" width={80} height={24} />}
          />
        )}
        {sections &&
          sections.map(section => (
            <StyledTab
              key={section.gid}
              label={section.name.substring(6, 10000)}
            />
          ))}
      </Tabs>
      {sections === null && (
        <div className="milestones">
          <MilestonePlaceholder />
          <MilestonePlaceholder />
          <MilestonePlaceholder />
          <MilestonePlaceholder />
          <MilestonePlaceholder />
          <MilestonePlaceholder />
        </div>
      )}
      {/* {sections && (
        <SwipeableViews
          animateHeight
          axis={'x'}
          index={active}
          onChangeIndex={handleChangeIndex}
        >
          {sections.map((section, i) => (
            <FeedTab key={section.gid} project={project} section={section} />
          ))}
        </SwipeableViews>
      )} */}
      {sections &&
        sections.map(
          (section, i) =>
            active === i && (
              <FeedTab key={section.gid} project={project} section={section} />
            )
        )}
    </Container>
  )
}

export default withRouter(Feed)
