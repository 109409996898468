import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
import 'firebase/functions' // <- needed if using httpsCallable
import 'firebase/analytics'
import { createStore, combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore' // <- needed if using firestore

const firebaseConfig = {
  apiKey: 'AIzaSyCkQYWlL-vv2vWSxOChKQq-khm2nW22ejs',
  authDomain: 'handstand-3e1c6.firebaseapp.com',
  databaseURL: 'https://handstand-3e1c6.firebaseio.com',
  projectId: 'handstand-3e1c6',
  storageBucket: 'handstand-3e1c6.appspot.com',
  messagingSenderId: '897797821927',
  appId: '1:897797821927:web:f9e329854500d300c9d942',
  measurementId: 'G-JQSVV4PQ6F'
}

firebase.initializeApp(firebaseConfig)

// Initialize other services on firebase instance
firebase.auth()
firebase.firestore()
firebase.functions()
firebase.analytics()

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore,
})

const initialState = {}
const store = createStore(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
