import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import {
  CircularProgress,
  AppBar,
  Toolbar,
  Container,
  Typography,
  Tabs,
  Tab,
  useMediaQuery
} from '@material-ui/core'
import GoogleButton from 'react-google-button'

import Projects from './components/Projects'

const Admin = props => {
  const [activeTab, setActiveTab] = useState(0)
  const fb = useFirebase()
  const auth = useSelector(state => state.firebase.auth)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const loginWithGoogle = () => {
    return fb.login({ provider: 'google', type: 'popup' })
  }

  if (auth && auth.email && !auth.email.includes('studioscience.com')) {
    fb.logout()
  }

  return (
    <div className="sc-admin">
      <AppBar position="sticky" color={prefersDarkMode ? 'inherit' : 'primary'}>
        <Container maxWidth="md">
          <Toolbar disableGutters>
            <Typography variant="h5" noWrap style={{ flexGrow: 1 }}>
              Projects
            </Typography>
            <Tabs
              indicatorColor="secondary"
              value={activeTab}
              onChange={(e, v) => setActiveTab(v)}
            >
              <Tab label="Active" />
              <Tab label="Archived" />
            </Tabs>
          </Toolbar>
        </Container>
      </AppBar>
      {!isLoaded(auth) ? (
        <CircularProgress />
      ) : isEmpty(auth) ? (
        <div className="center">
          <GoogleButton onClick={loginWithGoogle} />
        </div>
      ) : (
        <React.Fragment>
          {activeTab === 0 && <Projects />}
          {activeTab === 1 && <Projects archived />}
        </React.Fragment>
      )}
    </div>
  )
}

export default withRouter(Admin)
