import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useFirestore } from 'react-redux-firebase'
import {
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const ProjectModal = ({ project = {}, open, toggleEdit }) => {
  const [client, setClient] = useState(project.client || '')
  const [name, setName] = useState(project.name || '')
  const [asana_id, setAsanaID] = useState(project.asana_id || '')
  const [harvest_id, setHarvestID] = useState(project.harvest_id || '')
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'))
  const firestore = useFirestore()
  const update = e => {
    e.preventDefault()
    if (Object.keys(project).length) {
      firestore
        .collection('projects')
        .doc(project.id)
        .update({
          client,
          name,
          asana_id,
          harvest_id
        })
        .then(() => {
          toggleEdit(!open)
        })
    } else {
      firestore
        .collection('projects')
        .add({
          archived: false,
          created_on: new Date(),
          client,
          name,
          asana_id,
          harvest_id
        })
        .then(() => {
          toggleEdit(!open)
        })
    }
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => toggleEdit(!open)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Edit Project Details'}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={update} id="project-modal-form">
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <TextField
                required
                variant="filled"
                autoFocus
                id="client"
                label="Client Name"
                type="text"
                fullWidth
                value={client}
                onChange={({ target: { value } }) => setClient(value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                variant="filled"
                autoFocus
                id="name"
                label="Project Name"
                type="text"
                fullWidth
                value={name}
                onChange={({ target: { value } }) => setName(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="filled"
                autoFocus
                id="asana_id"
                label="Asana Project ID"
                type="text"
                fullWidth
                value={asana_id}
                onChange={({ target: { value } }) => setAsanaID(value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                autoFocus
                id="harvest_id"
                label="Harvest Project ID"
                type="text"
                fullWidth
                value={harvest_id}
                onChange={({ target: { value } }) => setHarvestID(value)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Paper elevation={0}>
          <Button onClick={() => toggleEdit(!open)} color="secondary" autoFocus>
            Cancel
          </Button>
        </Paper>
        <Paper elevation={0}>
          <Button type="submit" form="project-modal-form" autoFocus>
            Update
          </Button>
        </Paper>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(ProjectModal)
