import React from 'react'
import { Typography, Paper, Grid, Icon, useMediaQuery } from '@material-ui/core'
import moment from 'moment-timezone'

import Attachments from './Attachments'
import Comments from './Comments'

// import transform from '../../../helpers/transform'

export default ({ project, milestone, showAttachmentsOnly }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  return (
    <div id={`${milestone.gid}`} className="milestone">
      <Paper
        className={`milestone-card ${milestone.completed ? 'completed' : ''}`}
        elevation={prefersDarkMode ? 0 : 1}
      >
        <Grid container>
          <Grid style={{ flex: 1 }} item xs={'auto'}>
            {milestone.due_on && (
              <Typography
                variant="body2"
                component="div"
                className="inline-date"
              >
                <Icon
                  color="inherit"
                  fontSize="small"
                  style={{ marginRight: 6, position: 'relative', top: -1 }}
                >
                  date_range
                </Icon>
                {moment(milestone.due_on, 'YYYY-MM-DD').format('MMM Do')}
                {milestone.due_at && (
                  <React.Fragment>
                    ,{' '}
                    {moment(milestone.due_at)
                      .tz('America/Indiana/Indianapolis')
                      .format('h:mma z')}
                  </React.Fragment>
                )}
              </Typography>
            )}
            <Typography gutterBottom variant="h5">
              {milestone.name}
            </Typography>
            {showAttachmentsOnly ? null : (
              <Typography
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                component={'div'}
                className="notes"
                variant="body1"
                dangerouslySetInnerHTML={{ __html: milestone.html_notes }}
              >
                {/* {ReactHtmlParser(milestone.html_notes, {
                  transform
                })} */}
              </Typography>
            )}
          </Grid>
          <Grid item xs={'auto'}>
            {milestone.completed && (
              <Icon style={{ color: prefersDarkMode ? '#A3CC65' : '#1976d2' }}>
                check_circle
              </Icon>
            )}
          </Grid>
        </Grid>
        <Attachments project={project} milestone={milestone} />
        <Comments project={project} milestone={milestone} />
      </Paper>
    </div>
  )
}
