import React from 'react'
import { Container, useMediaQuery } from '@material-ui/core'
import Slider from 'react-slick'

import HCard from './Card'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default ({ project }) => {
  const matches = useMediaQuery('(min-width:768px)')

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Container className="harvest-card" maxWidth="sm">
      <Slider {...settings}>
        {project.harvest_id.split(',').map((id, i) => {
          return <HCard key={`${id}-${i}`} harvest={id} />
        })}
      </Slider>
    </Container>
  )
}
